const AKAMAI_CUT_10 = 10
const AKAMAI_CUT_16 = 16
const AKAMAI_CUT_40 = 40
const AKAMAI_CUT_70 = 70
const AKAMAI_CUT_100 = 100
const AKAMAI_CUT_280 = 280
const AKAMAI_CUT_320 = 320
const AKAMAI_CUT_368 = 368
const AKAMAI_CUT_480 = 480
const AKAMAI_CUT_493 = 493
const AKAMAI_CUT_539 = 539
const AKAMAI_CUT_582 = 582
const AKAMAI_CUT_640 = 640
const AKAMAI_CUT_721 = 721
const AKAMAI_CUT_768 = 768
const AKAMAI_CUT_800 = 800
const AKAMAI_CUT_828 = 828
const AKAMAI_CUT_960 = 960
const AKAMAI_CUT_1024 = 1024
const AKAMAI_CUT_1125 = 1125
const AKAMAI_CUT_1256 = 1256
const AKAMAI_CUT_1350 = 1350
const AKAMAI_CUT_1656 = 1656
const AKAMAI_CUT_1920 = 1920
const AKAMAI_CUT_2048 = 2048

export const extendedAkamaiBreakpoints = [
	AKAMAI_CUT_10,
	AKAMAI_CUT_16,
	AKAMAI_CUT_40,
	AKAMAI_CUT_70,
	AKAMAI_CUT_100,
	AKAMAI_CUT_280,
	AKAMAI_CUT_320,
	AKAMAI_CUT_368,
	AKAMAI_CUT_480,
	AKAMAI_CUT_493,
	AKAMAI_CUT_539,
	AKAMAI_CUT_582,
	AKAMAI_CUT_640,
	AKAMAI_CUT_721,
	AKAMAI_CUT_768,
	AKAMAI_CUT_800,
	AKAMAI_CUT_828,
	AKAMAI_CUT_960,
	AKAMAI_CUT_1024,
	AKAMAI_CUT_1125,
	AKAMAI_CUT_1256,
	AKAMAI_CUT_1350,
	AKAMAI_CUT_1656,
	AKAMAI_CUT_1920,
	AKAMAI_CUT_2048,
]
