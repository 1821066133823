import { env } from 'utils/envManager'

import { extendedAkamaiBreakpoints as akamaiBreakpoints } from './constants/extendedAkamaiBreakpoints'

export function pointOfInterestImageLoaderFactory(
	pointOfInterest: {
		x: number
		y: number
	},
	heightCalcFunction: (width: number) => number,
	widthCalculation?: (width: number) => number
) {
	return function imageLoader({ src, width }: { src: string; width: number }) {
		const viewportBreakpoint =
			akamaiBreakpoints.find((breakpointWidth) => breakpointWidth >= width) ??
			akamaiBreakpoints[akamaiBreakpoints.length - 1]
		const imageWidth = widthCalculation
			? widthCalculation(width)
			: viewportBreakpoint

		const imageHeight = Math.ceil(heightCalcFunction(imageWidth))

		const url = new URL(src, env.NEXT_PUBLIC_SITE_BASE_URL)
		const searchParams = new URLSearchParams({
			imdensity: '1',
		})

		url.search = searchParams.toString()
		url.search = `${url.search}&im=RegionOfInterestCrop,width=${imageWidth},height=${imageHeight},regionOfInterest=(${pointOfInterest.x},${pointOfInterest.y})`

		return url.toString()
	}
}
